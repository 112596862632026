<template>
  <div>
    <b-card>
      <div class="row mb-4">
        <div class="col-12 d-flex">
          <p class="h2 mr-auto text-secondary">Nouvelle activité</p>

          <modal-actions @close="$router.push({ name: 'imputations' })" />
        </div>
      </div>
      <form @submit.prevent="saveItem" class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <span class="h3 text-secondary">Informations générales</span>

          <b-button type="submit" pill :disabled="submitingForm">
            <b-spinner
              small
              v-if="submitingForm"
              class="text-white"
              label="Loading..."
            ></b-spinner>
            Confirmer</b-button
          >
          <hr class="w-100 bg-secondary" />
        </div>
        <div class="row">
          <!-- First Col -->
          <div class="col-12 col-md-6">
            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="Initiative:"
              label-class="font-weight-bold"
            >
              <b-form-select
                v-model="selectedInitiative"
                :options="initiativesList"
                required
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="Phase:"
              label-class="font-weight-bold"
            >
              <b-form-select
                v-model="selectedPhase"
                :options="phasesList"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="Tâche:"
              label-class="font-weight-bold"
            >
              <b-form-select
                v-model="newActivity.task"
                :options="tasksList"
              ></b-form-select>
            </b-form-group>
          </div>

          <!-- Second Col -->
          <div class="col-12 col-md-6">
            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="Charges estimées:"
              label-class="font-weight-bold"
            >
              <b-form-input
                v-model="newActivity.chargesEstime"
                required
                type="number"
                min="0"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="Charges restantes:"
              label-class="font-weight-bold"
            >
              <b-form-input
                v-model="newActivity.chargesRestantes"
                required
                type="number"
                min="0"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols-sm="3"
              label-align-sm="left"
              label="Semaine:"
              label-class="font-weight-bold"
            >
              <date-picker
                :input-attr="{ required: 'true' }"
                class="w-100"
                v-model="newActivity.semaine"
                type="week"
              ></date-picker>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  components: {
    ModalActions,
  },
  data: () => ({
    selectedInitiative: null,
    selectedPhase: null,
    submitingForm: false,
    newActivity: {
      chargesRestantes: 0,
      chargesEstime: 0,
      task: "",
      semaine: "",
    },
  }),
  methods: {
    saveItem() {
      this.submitingForm = true;
      this.$store
        .dispatch("imputation/createImputation", {
          ...this.newActivity,
          chargesRestantes: parseInt(this.newActivity.chargesRestantes),
          chargesEstime: parseInt(this.newActivity.chargesEstime),
        })
        .then((response) => {
          if (typeof response === "object")
            Swal.fire({
              title: "L'activité est bien créée !",
              type: "success",
              confirmButtonText: "OK",
            });
          this.submitingForm = false;
        });
    },
  },

  created() {
    this.$store.dispatch("initiative/fetchAllInitiatives");
    this.$store.dispatch("phase/fetchAllPhases");
    this.$store.dispatch("task/fetchAllData");
  },
  computed: {
    ...mapGetters("initiative", ["INITIATIVES"]),
    ...mapGetters("phase", ["PHASES"]),
    ...mapGetters("task", ["TASKS"]),
    initiativesList() {
      // this.initiative
      return this.INITIATIVES.map((initiative) => ({
        value: initiative.id,
        text: initiative.libelle,
      }));
    },
    phasesList() {
      this.selectedInitiative;

      return this.PHASES.map((phase) => ({
        ...phase,
        value: phase.id,
        text: phase.libelle,
      }));
    },

    tasksList() {
      return this.TASKS.map((task) => ({
        ...task,
        value: task["@id"],
        text: task.libelle,
      }));
    },
  },
};
</script>

<style>
.form-row div select {
  height: 100%;
}
</style>
